require('./bootstrap');
window.$ = window.jQuery = require('jquery');
var Carrusel = require('./modulos/home/carrusel');

new Carrusel($("#ctr-carrusel"), $("#carrusel"), 1600/800);

// function sizeFunction() {
//
//     $(".ctr-mensajes").each(function () {
//
//         if ($(window).width() < 769) {
//             new Carrusel($(this), $(this).find(".mensajes"), 1 / 1);
//         }
//         else if ($(window).width() >= 769 && $(window).width() <= 992) {
//             new Carrusel($(this), $(this).find(".mensajes"), 1600 / 800);
//         }
//         else if ($(window).width() > 992 && $(window).width() <= 1200) {
//             new Carrusel($(this), $(this).find(".mensajes"), 1600 / 800);
//         }
//         else {
//             new Carrusel($(this), $(this).find(".mensajes"), 1600 / 800);
//         }
//     });
// }
// sizeFunction();
// $(window).resize(function () {
//     sizeFunction();
// });
$(".ctr-mensajes").each(function () {
    new Carrusel($(this), $(this).find(".mensajes"), 1600 / 800);
});