import toastr from 'toastr';

(function () {
    var $cesta = $("#cesta");
    if ($cesta.length === 0) {
        return;
    }

    var $overlay = $('<div id="overlay-cesta"></div>');
    $overlay.removeClass("visible");

    $(document).ready(function(){
        $('body').append($overlay);
    });

    function abrir() {
        $overlay.addClass("visible");
        $cesta.addClass("abierta");
    }

    function cerrar() {
        $overlay.removeClass("visible");
        $cesta.removeClass("abierta");
    }

    function toggle() {
        if($cesta.hasClass("abierta")) {
            cerrar();
        } else {
            abrir();
        }
    }

    function actualizar() {
        $("form.cesta-actualizar").submit();
    }

    function mensajeError(jqXHR) {
        var texto = jqXHR.responseText;

        if(jqXHR.responseJSON && jqXHR.responseJSON.msgerror) {
            texto = jqXHR.responseJSON.msgerror;
        }
        toastr.error(texto);
    }

    $("#btn-menu-cesta").on("click", function (e) {
        e.preventDefault();
        toggle();
    });

    $cesta.on("click", "#cerrar-cesta", function (e) {
        e.preventDefault();
        cerrar();
    });

    $cesta.on("click", ".btn-cesta-continuar", function (e) {
        e.preventDefault();
        cerrar();
    });

    $cesta.on("click", ".btn-requerir-login", function (e) {
        e.preventDefault();
        cerrar();
        $("#modal").on("abierta", function () {
            $("#login-mensaje").removeClass("hidden");
            $("#modal").off();
        });
        $(".btn-login:first").trigger("click");
        localStorage.setItem("requerir-checkout", "si");
    });

    var agregando = false;
    function agregar(url, datos) {
        if (agregando === true) {
            return;
        }
        agregando = true;

        $.ajax({
            url: url,
            type: "POST",
            data: datos,
            cache: false,
            success: function(html) {
                $cesta.html(html);
                toastr.success('Producto agregado al carrito');
                $("#cesta-nro").text($cesta.find("form.cesta-actualizar").attr("data-total"));
            },
            error: mensajeError,
            beforeSend: function() {
                $("#cargador").show();
            },
            complete: function() {
                agregando = false;
                $("#cargador").hide();
            }
        });
    }

    $(".js-cesteable").on("click", ".js-btn-comprar", function (e) {
        e.preventDefault();

        var id = $(this).attr("data-id");
        var cantidad = $(this).parents(".producto").find("input[name='cantidad']").val();
        agregar("/cesta/agregar", {id: id, cantidad: cantidad});
    });

    $("form.cesta-agregar").on("submit", function (e) {
        e.preventDefault();
        var $form = $(this);
        agregar($form.attr("action"), $form.serialize());
    });

    $cesta.on("click", ".cesta-item-borrar", function () {
        $.ajax({
            url: "/cesta/borrar",
            type: "POST",
            data: {id: $(this).attr("data-id")},
            cache: false,
            success: function(html) {
                $cesta.html(html);
                var total = 0;
                if ($cesta.find("form.cesta-actualizar").length > 0) {
                    total = $cesta.find("form.cesta-actualizar").attr("data-total");
                }
                $("#cesta-nro").text(total);
            },
            error: mensajeError,
            beforeSend: function() {
                $("#cargador").show();
            },
            complete: function() {
                $("#cargador").hide();
            }
        });
    });

    $cesta.on("submit", "form.cesta-actualizar", function (e) {
        e.preventDefault();

        var $form = $(this);
        $.ajax({
            url: $form.attr("action"),
            type: "POST",
            data: $form.serialize(),
            cache: false,
            success: function(html) {
                $cesta.html(html);
                $("#cesta-nro").text($cesta.find("form.cesta-actualizar").attr("data-total"));
            },
            error: mensajeError,
            beforeSend: function() {
                $("#cargador").show();
            },
            complete: function() {
                $("#cargador").hide();
            }
        });
    });

    $cesta.on("click", ".btn-vaciar", function (e) {
        e.preventDefault();

        $.ajax({
            url: $(this).attr("href"),
            type: "GET",
            cache: false,
            success: function(html) {
                $cesta.html(html);
                $("#cesta-nro").text("0");
            },
            error: mensajeError,
            beforeSend: function() {
                $("#cargador").show();
            },
            complete: function() {
                $("#cargador").hide();
            }
        });
    });

    $cesta.on("click", ".cesta-entero-asc", function () {
        var $input = $(this).parents(".ctr-cesta-cantidad").find('input');
        var valor = parseInt($input.val());
        valor++;
        $input.val(valor)
        actualizar();
    });

    $cesta.on("click", ".cesta-entero-desc", function () {
        var $input = $(this).parents(".ctr-cesta-cantidad").find('input');
        var valor = parseInt($input.val());
        valor--;
        if (valor < 1) {
            valor = 1;
        }
        $input.val(valor)
        actualizar();
    });

    var $ctr = $("#contenedor");

    $ctr.on("click", ".entero-asc", function () {
        var $input = $(this).parents(".ctr-button-cantidad").find('input');
        var valor = parseInt($input.val());
        valor++;
        $input.val(valor)
    });

    $ctr.on("click", ".entero-desc", function () {
        var $input = $(this).parents(".ctr-button-cantidad").find('input');
        var valor = parseInt($input.val());
        valor--;
        if (valor < 1) {
            valor = 1;
        }
        $input.val(valor)
    });
})();
