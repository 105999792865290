window.$ = window.jQuery = require('jquery');
var Hammer = require('hammerjs');

function Carrusel($ctrCarrusel, $carrusel, relacionAspecto) {
    var tmsg = $ctrCarrusel.find(".anuncio").length;
    var apuntado = 0;
    var animando = false;
    var tempo = null;

    $carrusel.each(function () {
        $(this).find(".anuncio").first().addClass("entrante-izq").addClass("seleccionado");
    });

    function arrancar() {
        if (tmsg > 1) {
            tempo = window.setTimeout(moverDer, 20000);
        }
    }

    function pausar() {
        clearTimeout(tempo);
    }

    $carrusel.hover(pausar, function () {
        tempo = window.setTimeout(moverDer, 3000);
    });

    function mover(indice) {
        if (indice === apuntado || animando === true) {
            return;
        }
        animando = true;
        window.clearTimeout(tempo);
        var ultimo = tmsg - 1;
        var dir = "izq";

        if (apuntado === 0 && indice === ultimo) {
            dir = "der";
        } else if (apuntado === ultimo && indice === 0) {
            dir = "izq";
        } else if (indice < apuntado) {
            dir = "der";
        } else if (indice > apuntado) {
            dir = "izq";
        }

        $carrusel.each(function () {
            var $apuntado = $(this).find(".anuncio").eq(apuntado);
            var $proximo = $(this).find(".anuncio").eq(indice);

            $apuntado.removeClass("entrante-izq").removeClass("entrante-der").removeClass("seleccionado");
            $proximo.removeClass("saliente-izq").removeClass("saliente-der");
            $apuntado.addClass("saliente-" + dir);
            $proximo.addClass("entrante-" + dir).addClass("seleccionado");
        });

        window.setTimeout(function () {
            animando = false;
        }, 1000);
        apuntado = indice;
        arrancar();
    }

    var moverIzq = function () {
        var anterior = apuntado - 1;
        if (anterior < 0) {
            anterior = tmsg - 1;
        }
        mover(anterior);
    };

    var moverDer = function () {
        var ultimo = tmsg - 1;
        var posterior = apuntado + 1;
        if (posterior > ultimo) {
            posterior = 0;
        }
        mover(posterior);
    };

    $ctrCarrusel.on("click", ".izq", moverIzq);

    $ctrCarrusel.on("click", ".der", moverDer);

    var hammertime = new Hammer($ctrCarrusel[0]);

    hammertime.on('swipeleft', moverDer);

    hammertime.on('swiperight', moverIzq);

    function ajustar() {
        var ancho = $ctrCarrusel.width();
        var alto = ancho /relacionAspecto;
        $carrusel.width(ancho).height(alto);
        $carrusel.find(".anuncio").width(ancho).height(alto);
    }

    $(window).on("resize", ajustar);

    ajustar();
    arrancar();

}

module.exports = Carrusel;
