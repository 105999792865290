var NProgress = require("nprogress");
NProgress.start();
try {
    window.$ = window.jQuery = require('jquery');
} catch (e) {}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr("content")
    }
});

var Headroom = require("headroom.js");
var headroom  = new Headroom(document.getElementById("header"), {
    "offset": 350,
    "tolerance": 5
});
headroom.init();

// var headroomElevador  = new Headroom(document.getElementById("elevador"), {
//     "offset": 205,
//     "tolerance": 5
// });
// headroomElevador.init();

require('./modulos/buscador');
require('./modulos/cesta');

require("./vendor/mega/navigation");
require('cookieconsent/src/cookieconsent');

/* global cookiesHref */
window.onload = function() {
    NProgress.done();
    try {
        window.cookieconsent.initialise({
            "palette": {
                "popup": {
                    "background": "#171717",
                    "text": "#e0e0e0"
                },
                "button": {
                    "background": "#000"
                }
            },
            "theme": "edgeless",
            "position": "bottom-left",
            "content": {
                "message": 'Utilizamos cookies propias y de terceros para realizar diferentes análisis de nuestra web para mejorar tanto su experiencia como la nuestra y poder ofrecerle mejores servicios. Si continúas navegando, consideramos que consientes. Puedes consultar nuestra Política de Cookies, en la que además encontrarás la forma de configurar tu navegador web para gestionar las cookies y rechazarlas.',
                "dismiss": 'Acepto las cookies',
                "link": 'Configurar cookies',
                "href": cookiesHref
            }
        });
    } catch (e) {
    }
};

$("#menu-principal").navigation({
    // responsive: false,
    hideDuration: 150,
    showDelayDuration: 0,
    hideDelayDuration: 150,
    effect: "fade",
    mobileBreakpoint:769
});

$(".js-cambio-version").on("click", function (e) {
    e.preventDefault();
    $.ajax({
        url: $(this).attr("href"),
        type: "POST",
        cache: false,
        success: function() {
            window.location.reload(true);
        },
        error: function (jqXHR) {
            ventana('Error', jqXHR.responseText);
        },
        beforeSend: function() {
            $("#cargador").show();
        },
        complete: function() {
            $("#cargador").hide();
        }
    });
});
