window.$ = window.jQuery = require('jquery');
require('../vendor/mega/navigation');

(function () {
    var $resultado = $(".buscador-resultados:last");
    var $buscador = $("#buscador");
    var urlBuscador = $buscador.find("form").attr("action");
    var urlPagina = location.href;
    var filtro = {};
    var esPagina = $resultado.hasClass("pagina-buscador");

    function inicializadorSelectores() {
        $(".selector-buscador").navigation({
            hideDuration: 600,
            showDelayDuration: 0,
            hideDelayDuration: 0,
            responsive: true
        });
    }

    if (esPagina) {
        filtro = JSON.parse($resultado.attr("data-filtro"));
    }

    inicializadorSelectores();

    $buscador.on("keyup", "input[name=texto]", function () {
        var texto = $(this).val();

        if (texto.length < 3) {
            $resultado.empty();
            $resultado.removeClass("visible");
            return;
        }

        filtro.texto = texto;
        actualizar();
    });

    function presentador(html) {
        $resultado.html(html).addClass("visible");

        if (esPagina) {
            window.history.pushState([], "Resultados para "+filtro.texto, urlPagina);
        }

        $("html, body").animate({ scrollTop: 0 }, "slow");

        inicializadorSelectores();
    }

    $resultado.on("click", "#cerrar-buscador", function(e) {
        e.preventDefault();
        $buscador.find("input[name=texto]").val('');
        $resultado.empty();
        $resultado.removeClass("visible");
    });


    $resultado.on("click", ".mostrar-todo", function (e) {
        e.preventDefault();
        $.each(filtro, function (param) {
            if (param !== "orden" && param !== "texto") {
                delete filtro[param];
            }
        });
        urlPagina = parametrizador();
        actualizar();
    });

    $resultado.on("click", "a[data-slug], span[data-slug]", function (e) {
        e.preventDefault();
        var param = $(this).attr("data-param");
        var slug = $(this).attr("data-slug");

        if (param === "orden") {
            filtro[param] = slug;
        } else {
            if (typeof filtro[param] !== 'undefined') {
                if(filtro[param] === slug) {
                    delete filtro[param];
                } else {
                    filtro[param] = slug;
                }
            } else {
                filtro[param] = slug;
            }
        }
        urlPagina = parametrizador();
        actualizar();
    });

    $resultado.on("click", ".paginador a", function (e) {
        e.preventDefault();
        urlPagina = $(this).attr("href");

        $.ajax({
            url: $(this).attr("href"),
            type: "POST",
            data: filtro,
            cache: false,
            success: function(html) {
                presentador(html)
            },
            error: function (jqXHR) {
                console.log('Error', jqXHR.responseText);
            },
            beforeSend: function() {
                $("#cargador").show();
            },
            complete: function() {
                $("#cargador").hide();
            }
        });
    });

    function actualizar() {
        $.ajax({
            url: urlBuscador,
            type: "POST",
            data: filtro,
            cache: false,
            success: function(html) {
                presentador(html)
            },
            error: function (jqXHR) {
                console.log('Error', jqXHR.responseText);
            },
            beforeSend: function() {
                $("#cargador").show();
            },
            complete: function() {
                $("#cargador").hide();
            }
        });
    }

    function parametrizador() {
        var s = location.protocol + '//' + location.host + location.pathname + '?';
        var i = 0;
        $.each(filtro, function(pmt, valor) {
            if (i > 0) {
                s += "&";
            }
            s += pmt+"="+valor;
            i++;
        });

        return s;
    }

})();
